import React from 'react';
import styled from 'styled-components';
import { TURBINE_BKG_DARK } from '../../constants/assets';

const TurbineLoading = styled.div`
  align-items: center;
  background-color: #f8f9fa;
  color: #6c757d;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  i {
    font-size: 2rem;
    margin-bottom: .5rem;
  }

  &.position-fixed {
    z-index: 100;
  }

  &.theme-dark {
    background-color: #000000;
    background-image: url(${TURBINE_BKG_DARK});
    background-repeat: repeat;
    color: white;
  }
`;

const Loading = ({ text, subtext, className = '' }) => {
  return (
    <TurbineLoading
      className={`turbine-loading ${className}`}
    >
      <i
        className="fas fa-fan fa-spin large"
        aria-hidden="true"
        style={{ fontSize: '3rem' }}
      />
      <div className="text-capitalize">
        {text || 'Loading'}
      </div>
      {subtext && (
        <div className="small">
          {subtext}
        </div>
      )}
    </TurbineLoading>
  );
};

export default Loading;
