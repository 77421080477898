import { fetchPage } from '../services/pages';
// import { getPageLink } from './PageLinks';
// import { getResourcesByParentId } from './Resources';
import {
  RESET_PAGE,
  SET_PAGE
  // SET_ENTRY_ORG_ID,
} from './types';

export const getPage = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchPage(id)
        .then((page) => {
          if (!page?.id) {
            reject(new Error('No Page found (id)'));
            return;
          }

          // ORG CHECK
          // dispatch({
          //   type: SET_ENTRY_ORG_ID,
          //   entryOrgId: page?.orgId || null
          // });

          dispatch({
            type: SET_PAGE,
            page: page?.id ? { ...page } : null
          });
          // dispatch(getResourcesByParentId(page?.id, 'pages'));
          resolve(page);
        })
        .catch((error) => {
          console.error('get page', error);
          reject(error);
        });
    });
  };
};

export const resetPage = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGE });
  };
};
