export const GMAIL_SEND = '/v1/external/gmail/send';
export const FIREBASE_STORE_COLLECTION = '/v1/external/firebase/store';
export const OPEN_API_CREATE_COMPLETION = '/v1/external/openai/create-completion';
export const ANALYTICS_TRACK = '/v1/analytics/track';

// Supabase
export const SUPABASE_BASE_URL = '/external/supabase';
export const SUPABASE_VECTOR_SEARCH = '/v1/external/supabase/vectorSearch';
export const SUPABASE_CHAT_ENGINE = '/v1/external/supabase/chatEngine';
// PAGINATION
export const DEFAULT_PAGINATION_LIMIT = 20;
