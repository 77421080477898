export const TURBINE_LOGO = 'https://s3.us-west-2.amazonaws.com/turbineworkforce.com/turbine-workforce-logo.jpg';
export const TURBINE_LOGO_WHITE = 'https://s3.us-west-2.amazonaws.com/turbineworkforce.com/turbine-workforce-logo-white.png';
export const TURBINE_LOGO_BLACK = 'https://s3.us-west-2.amazonaws.com/turbineworkforce.com/turbine-workforce-logo-black.png';
export const IMAGE_TURBINE_RETRO_ICON_WHITE = 'https://turbine-content.s3.us-east-2.amazonaws.com/turbine-retro-icon-white.png';
export const TURBINE_BKG_DARK = 'https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png';
export const DEFAULT_ORG_LOGO = 'https://turbine-content.s3.us-east-2.amazonaws.com/default-org-logo.jpg';
export const DEFAULT_PROFILE_PIC = 'https://turbine-content.s3.us-east-2.amazonaws.com/default-profile-picture.png';
export const RELOAD_IMG = 'https://turbine-content.s3.us-east-2.amazonaws.com/code-monkey-620x284.jpg';
export const PATHWAYS_PIXELATED_MAP_LIGHT = 'https://s3.us-west-2.amazonaws.com/turbineworkforce.com/pixelated-map-light.jpg';
export const DEFAULT_AGENT_IMAGE = 'https://turbine-content.s3.us-east-2.amazonaws.com/bot-helmet.png';
//
export const IMG_LINKEDIN_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/linkedin.png';
export const LETS_ENCRYPT_LOGO = 'https://turbine-content.s3.us-east-2.amazonaws.com/le-logo-wide.png';
export const US_DEPT_LABOR = 'https://turbine-content.s3.us-east-2.amazonaws.com/seal-US-department-labor.png';
// PDFs
export const EMPTY_SIGNATURE = 'https://turbine-content.s3.us-east-2.amazonaws.com/signature-empty.png';
export const PDF_APPRENTICE_AGREEMENT = 'https://turbine-content.s3.us-east-2.amazonaws.com/pdfs/Appendix-B-Template-2023--Apprentice-Agreement.pdf';
// PAGE
export const IMAGE_TOOLKIT_EMPTY = 'https://turbine-content.s3.us-east-2.amazonaws.com/toolkit-empty.png';
