import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { SLASH } from '../../constants/routes';
import PoweredBy from '../../components/auth/Container/PoweredBy';

const PageLinkMessage = () => {
  const params = useParams();
  const history = useHistory();
  // Invite Message
  const messageType = params?.messageType || null;
  const title = useMemo(() => {
    if (messageType === 'success') {
      return 'Thank you';
    }

    return 'Invalid Invitation'; // error
  }, [messageType]);

  const body = useMemo(() => {
    if (messageType === 'success') {
      return 'Your information was submitted.';
    }

    return 'This invitation is no longer valid.'; // error
  }, [messageType]);

  if (!messageType) {
    history.replace(SLASH);
    return null;
  }

  return (
    <div
      className='d-flex align-items-center justify-content-center flex-column'
      style={{
        height: '100vh'
      }}
    >
      <div className='card mt-3'>
        <div className='card-header text-center h5 m-0 font-weight-bold'>
          {title}
        </div>
        <div className='card-body text-center'>
          {messageType === 'error' && (
            <FaExclamationTriangle
              className='text-danger mx-auto'
              size={36}
            />
          )}

          {messageType === 'success' && (
            <FaCheckCircle
              className='text-keppel mx-auto'
              size={36}
            />
          )}

          <p className='mt-3'>
            {body}
          </p>
        </div>
        <div className='card-footer text-center'>
          A new invitation will be sent if further information is needed.
        </div>
      </div>
      <div className="my-3 text-muted">
        <PoweredBy
          trackingCode="inviteMessage"
        />
      </div>
    </div>
  );
};

export default PageLinkMessage;
