import React, { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const withRouteClassName = (Component) => {
  const WithRouteClassName = (props) => {
    const matchPAGE = useRouteMatch({
      path: ROUTES.PAGE,
      strict: true,
      sensitive: true
    });

    const className = useMemo(() => {
      if (matchPAGE) {
        return 'route-page';
      }

      return '';
    }, [matchPAGE]);

    useEffect(() => {
      document.body.classList.add(className);

      return function cleanup() {
        document.body.classList.remove(className);
      };
    }, [className]);

    return <Component {...props} />;
  };

  return WithRouteClassName;
};

export default withRouteClassName;
