import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getOrg } from '../../actions';
import { getCourses } from '../../actions/Courses';
import { getJobProfiles } from '../../actions/JobProfiles';
import { getPathway } from '../../actions/Pathways';
import {
  ANDROID_192_ICON, ANDROID_512_ICON, APPLE_TOUCH_ICON, ROBOTS_OFF, ROBOTS_ON
} from '../../constants/globals';
import { PATHWAY } from '../../constants/icons';
import { TURBINE_PAGE } from '../../constants/urls';
import { SET_PAGE_ORG } from '../../actions/types';
import generateWebManifest from '../../utils/generateWebManifest';
import isPublished from '../../utils/isPublished';
import Loading from '../Loading';
import RenderMarkdown from '../ManageContent/RenderMarkdown';
import Tabs from './Tabs';
import './style.css';

const PathwayContainer = styled.div`
  background-image: url(https://s3.us-west-2.amazonaws.com/turbineworkforce.com/webb-dark.png);
  background-repeat: repeat;
  background-color: lightgray;
`;

const PathwayPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const courses = useSelector((state) => state.courses);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  let whichCourses = null;
  const orgId = pageOrganization?.id || null;
  const orgName = pageOrganization?.name || null;
  const pathwayTitle = pathway?.title || 'Pathway';
  const pathwayCourseIds = pathway?.courseIds || null;
  const isPublic = pathway?.isPublic || null;
  const searchEnginesEnabled = pathway?.searchEnginesEnabled || null;
  // Params
  const pathwayId = params?.pathwayId || null;
  const orgSlug = params?.orgSlug || null;
  // Dynamic Web Manifest
  const appleTouchIcon = pageOrganization?.appleTouchIcon || APPLE_TOUCH_ICON;
  const androidIconSmall = pageOrganization?.androidIconSmall || ANDROID_192_ICON;
  const androidIconLarge = pageOrganization?.androidIconLarge || ANDROID_512_ICON;

  useEffect(() => {
    document.body.classList.add('pathway-page');

    dispatch(getOrg({
      reduxKey: 'pageOrganization',
      actionType: SET_PAGE_ORG,
      slug: orgSlug,
      select: [
        'fields.name',
        'fields.orgLogo',
        'fields.slug',
        'fields.appleTouchIcon',
        'fields.androidIconSmall',
        'fields.androidIconLarge'
      ]
    })).then((response) => {
      dispatch(getCourses({ orgId: response.pageOrganization.id }));
      dispatch(getJobProfiles({ orgId: response.pageOrganization.id }));
    });
  }, [orgSlug]);

  useEffect(() => {
    if (pathwayId) {
      dispatch(getPathway(pathwayId));
    }
  }, [pathwayId]);

  if (courses) {
    whichCourses = courses.filter((c) => isPublished(c));
    // Exclude test courses if they exist and the user is not a test user
    whichCourses = whichCourses.filter(((c) => {
      if (c.testClass && !currentUser.testUser) return c;

      return c;
    }));
  }

  // Dynamic Web Manifest
  useEffect(() => {
    const manifest = document.getElementById('dynamic-manifest');

    // The id attribute is removed in generateWebManifest
    // to ensure this runs one time
    if (orgId && manifest) {
      generateWebManifest({
        orgName,
        androidIconSmall,
        androidIconLarge
      });
    }
  }, [orgId]);
  // /Dynamic Web Manifest

  if (!pathway || !whichCourses) {
    return (
      <Loading />
    );
  }

  if (!isPublic) {
    return (
      <div className="row mt-2">
        <div className="col-sm-12">
          <div className="mb-5">
            <div
              className="p-3 border mt-2 bg-white"
            >
              <div className='h5 m-0'>
                <i className={PATHWAY} /> This pathway is private.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const pathwayCourses = pathwayCourseIds ? pathwayCourseIds.map((courseId) => whichCourses.find((course) => course.id === courseId)) : null;

  return (
    <Fragment>
      <Helmet>
        <title>{pathwayTitle} | {orgName}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <meta name="robots" content={searchEnginesEnabled ? ROBOTS_ON : ROBOTS_OFF} />
      </Helmet>
      <div className="row mt-2">
        <div className="col-sm-12">
          <div className="mb-5">
            <div
              className="p-3 border mt-2 bg-white"
            >
              <div className='h5 m-0'>
                <i className={PATHWAY} /> {pathway.title}
              </div>
            </div>

            <Tabs
              activeTab='pathway'
            />

            <div
              className="bg-white border-bottom border-left border-right p-3"
            >
              <PathwayContainer>
                {pathwayCourses && (
                  <ul className="timeline">
                    {pathwayCourses.map((pathwayCourse, i) => {
                      if (!pathwayCourse) return null;

                      const isPrivate = !pathwayCourse.isPublic || pathwayCourse.inviteOnly;

                      return (
                        <li
                          key={`pathwayCourse-${i}`}
                          className="timeline-inverted"
                        >
                          <div className="timeline-badge bg-white">
                            {i + 1}
                          </div>
                          <div className="timeline-panel">
                            <div className="timeline-heading">
                              <h5 className="timeline-title font-weight-bold mb-2">
                                {pathwayCourse.title}
                              </h5>
                              <div>
                                <RenderMarkdown
                                  source={pathwayCourse.description}
                                />
                              </div>
                              <div
                                className="d-flex align-items-center"
                              >
                                {!isPrivate && (
                                  <a
                                    className={`btn btn-sm btn-primary ${isPrivate ? 'disabled' : ''}`}
                                    href={`${TURBINE_PAGE}/o/${orgSlug}/courses/id/${pathwayCourse.id}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    title="Learn more"
                                  >
                                    Learn more
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                    {/* <li className="timeline-inverted">
                      <div className="timeline-badge bg-white">
                        {pathwayCourses.length + 1}
                      </div>
                      <div className="timeline-panel">
                        <div className="timeline-heading">
                          <h4 className="timeline-title">
                            Web Developer certificate
                          </h4>
                        </div>
                      </div>
                    </li> */}
                  </ul>
                )}
              </PathwayContainer>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PathwayPage;
