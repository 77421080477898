import React from 'react';
import LinkWidgetModule from './Modules/LinkWidgetModule';
import EmbedWidgetModule from './Modules/EmbedWidgetModule';

const Module = ({
  type, className, isConfig = false, moduleData
}) => {
  let module = null;

  switch (type) {
    case 'LINK_WIDGETS_LIST':
      module = <LinkWidgetModule widgetIds={moduleData.selectedIds} />;
      break;
    case 'EMBED_WIDGETS_LIST':
      module = <EmbedWidgetModule widgetIds={moduleData.selectedIds} />;
      break;
    default:
      module = null;
  }
  return (
    <div
      className={className}
      style={isConfig ? { pointerEvents: 'none' } : {}}
    >
      {module}
    </div>
  );
};

export default Module;
