import {
  fetchSupabaseEntry
} from './supabaseProxy';

const table = 'pages';

export const fetchPage = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};
