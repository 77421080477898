import { fetchSupabaseEntry } from './supabaseProxy';

const table = 'dashboards';

export const fetchDashboard = async (id) => {
  try {
    const response = await fetchSupabaseEntry({ table, id });

    return response;
  } catch (error) {
    console.error(error);
  }
};
