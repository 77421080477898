import { fetchSupabaseEntry } from './supabaseProxy';

const table = 'pageLinks';

export const fetchPageLink = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};
