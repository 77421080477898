import app from 'firebase/app';
import 'firebase/auth';
import { isLocalhost } from '../serviceWorker';
import { TURBINE, TURBINE_ADMIN, TURBINE_AUTH } from '../constants/urls';

const createContinueUrl = ({ href, error }) => {
  let continueUrlFallback = TURBINE_ADMIN;

  if (href.includes(TURBINE)) {
    continueUrlFallback = TURBINE;
  }

  if (error?.code || error?.message) {
    return continueUrlFallback;
  }

  if (href.includes('/login')) {
    return continueUrlFallback;
  }

  if (!isLocalhost && href.includes('http:')) {
    // current href isn't secure, make it secure when they redirect back to app/admin
    href = href.replace('http:', 'https:');
  }

  return href;
};

export const redirectToAuth = ({ organizationSlug, error, continueUrl }) => {
  const redirectUrlBase = TURBINE_AUTH;
  const redirectUrl = organizationSlug ? `${redirectUrlBase}/login/${organizationSlug}` : `${redirectUrlBase}/login`;
  const paramsObj = {
    continueUrl: continueUrl || createContinueUrl({ href: window.location.href, error })
  };
  const searchParams = new URLSearchParams(window.location.search);
  const action = searchParams.get('action');

  if (error?.code) {
    paramsObj.errorCode = error.code;
  }

  if (error?.message) {
    paramsObj.errorMessage = error.message;
  }

  if (action) {
    paramsObj.action = searchParams.get('action');
  }

  logUserOut().then(() => {
    const redirectUrlSearchParams = new URLSearchParams(paramsObj);

    window.location = `${redirectUrl}?${redirectUrlSearchParams.toString()}`;
  });
};

export const logUserOut = () => {
  return new Promise((resolve, reject) => {
    app.auth().signOut().then(() => {
      resolve();
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};
