import { fetchPageLink } from '../services/pageLinks';
import {
  // SET_ENTRY_ORG_ID,
  SET_PAGE_LINK,
  RESET_PAGE_LINK
} from './types';

export const getPageLink = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchPageLink(id)
        .then((pageLink) => {
          if (!pageLink?.id) {
            reject(new Error('No Page found (id)'));
            return;
          }

          // ORG CHECK
          // dispatch({
          //   type: SET_ENTRY_ORG_ID,
          //   entryOrgId: page?.orgId || null
          // });

          dispatch({
            type: SET_PAGE_LINK,
            pageLink: pageLink?.id ? { ...pageLink } : null
          });
          resolve(pageLink);
        })
        .catch((error) => {
          console.error('get pageLink', error);
          reject(error);
        });
    });
  };
};

export const resetPageLink = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGE_LINK });
  };
};
