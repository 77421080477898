import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOrg } from '../../actions';
import { getPathway } from '../../actions/Pathways';
import { PATHWAY } from '../../constants/icons';
import { jobProfileIframeUrl } from '../../services/pathways';
import { SET_PAGE_ORG } from '../../actions/types';
import Loading from '../Loading';
import Tabs from './Tabs';

const JobProfile = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const pathway = useSelector((state) => state.pathway);
  // Organization
  const orgId = pageOrganization?.id || null;
  // Params
  const orgSlug = params?.orgSlug || null;
  const pathwayId = params?.pathwayId || null;
  // Pathway
  const jobProfileId = pathway?.jobProfileId || null;

  const iframeSrc = useMemo(() => {
    return jobProfileIframeUrl({ jobProfileId, orgId, hideHeaderTitle: true });
  }, [jobProfileId, orgId]);

  useEffect(() => {
    document.body.classList.add('pathway-page');

    if (!orgId) {
      dispatch(getOrg({
        reduxKey: 'pageOrganization',
        actionType: SET_PAGE_ORG,
        slug: orgSlug,
        select: [
          'fields.name',
          'fields.orgLogo',
          'fields.slug',
          'fields.appleTouchIcon',
          'fields.androidIconSmall',
          'fields.androidIconLarge'
        ]
      }));
    }
  }, [dispatch, orgId, orgSlug]);

  useEffect(() => {
    if (pathwayId) {
      dispatch(getPathway(pathwayId));
    }
  }, [dispatch, pathwayId]);

  if (!pathway?.id || !jobProfileId) {
    return (
      <Loading />
    );
  }

  return (
    <div className="row mt-2">
      <div className="col-sm-12">
        <div className="mb-5">
          <div
            className="p-3 border mt-2 bg-white"
          >
            <div className='h5 m-0'>
              <i className={PATHWAY} /> {pathway?.title}
            </div>
          </div>

          <Tabs
            activeTab='jobProfile'
          />

          <div
            className="bg-white border-bottom border-left border-right p-3"
          >
            <iframe
              title={pathway?.title}
              src={iframeSrc}
              style={{ border: 0, height: 'calc(100vh - 30px)' }}
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobProfile;
