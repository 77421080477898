import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import publicPageRoute from '../../utils/publicPageRoute';

const Tabs = ({
  activeTab = 'pathway',
  className = 'mt-3'
}) => {
  // Redux
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  const pathwayId = pathway?.id || null;
  const jobProfileId = pathway?.jobProfileId || null;
  const orgSlug = pageOrganization?.slug || null;

  return (
    <div className={className}>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link ${activeTab === 'pathway' ? 'active' : ''}`}
            to={publicPageRoute({ route: `pathways/${pathwayId}`, orgSlug })}
            title="All Pathways"
            disabled={!pathwayId}
          >
            Pathway
          </Link>
        </li>

        {jobProfileId && (
          <li className="nav-item">
            <Link
              className={`nav-link ml-2 ${activeTab === 'jobProfile' ? 'active' : ''}`}
              to={publicPageRoute({ route: `pathways/${pathwayId}/job-profile/${jobProfileId}`, orgSlug })}
              title="Job Profile"
              disabled={!pathwayId}
            >
              Job Profile
            </Link>
          </li>
        )}

        {jobProfileId && (
          <li className="nav-item">
            <Link
              className={`nav-link ml-2 ${activeTab === 'certificates' ? 'active' : ''}`}
              to={publicPageRoute({ route: `pathways/${pathwayId}/certificates`, orgSlug })}
              title="Certificates"
              disabled={!pathwayId}
            >
              Certificates
            </Link>
          </li>
        )}

        {/* <li className="nav-item">
          <Link
            className={`nav-link ml-2 ${activeTab === 'courses' ? 'active' : ''}`}
            to={publicPageRoute({ route: `pathways/${pathwayId}/courses`, orgSlug })}
            title="Courses"
          >
            Courses
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Tabs;
