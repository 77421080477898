import * as twApi from './tw-api';
import { ANALYTICS_TRACK } from '../constants/api';

export const analyticsTracking = async ({
  refId,
  refTable,
  isUnique,
  userId,
  orgId
}) => {
  if (!refId) {
    throw new Error('refId is required');
  }

  if (!refTable) {
    throw new Error('refTable is required');
  }

  const language = window.navigator?.language;
  const platform = window.navigator?.platform;

  const data = {
    refId,
    refTable,
    ...(isUnique ? { isUnique } : {}),
    ...(userId ? { userId } : {}),
    ...(orgId ? { orgId } : {}),
    ...(language ? { language } : {}),
    ...(platform ? { platform } : {})
  };

  const dataToSave = JSON.stringify(data);

  try {
    const response = await twApi.post(ANALYTICS_TRACK, '', dataToSave);

    return response;
  } catch (error) {
    console.error(`analytics/track ${refTable}`, error);
    throw new Error(error);
  }
};
